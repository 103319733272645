import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';

@Injectable()
export class WindowService {
  private _window: Window;

  constructor(@Inject(PLATFORM_ID) platformId: Record<string, unknown>) {
    if (!isPlatformBrowser(platformId)) {
      this._window = { navigator: { userAgent: 'fakeAgent' } } as Window;
    } else {
      this._window = window;
    }
  }

  get nativeWindow(): Window {
    return this._window;
  }
}

export const WINDOW = new InjectionToken('window');

const windowFactory = (windowService: WindowService) => {
  return windowService.nativeWindow;
};

export const windowProvider = {
  provide: WINDOW,
  useFactory: windowFactory,
  deps: [WindowService]
};
