import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import {
  Inject,
  Injectable,
  NgZone,
  Optional,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
  ViewEncapsulation,
} from '@angular/core'
import { loadStripe } from '@stripe/stripe-js/pure'
import { PaymentConfig } from '../models'

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private renderer2!: Renderer2

  // stripe$ = new BehaviorSubject(null)

  initialized = false

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    @Optional() @Inject(DOCUMENT) private document: Document,
    @Inject(PaymentConfig) private config: PaymentConfig,
    private rendererFactory: RendererFactory2,
    private zone: NgZone,
  ) {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    this.renderer2 = this.rendererFactory.createRenderer(this.document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {},
    })
  }

  init() {
    this.injectScript()
  }

  private injectScript(): void {
    if (!isPlatformBrowser(this.platformId) || this.initialized) {
      return
    }

    this.zone.runOutsideAngular(() => {
      const existingStripeScript = document.getElementById('stripe-api')

      if (existingStripeScript == null && this.renderer2 && this.renderer2.appendChild) {
        // Create the map script in document
        const stripeScript = document.createElement('script')
        stripeScript.type = 'text/javascript'
        stripeScript.async = true
        stripeScript.src = `https://js.stripe.com/v3/`
        stripeScript.id = 'stripe-api'

        this.renderer2.appendChild(this.document.head, stripeScript)
      }
    })
  }

  get stripe() {
    return loadStripe(this.config.stripeToken)
  }
}
