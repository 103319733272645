import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { WindowService, windowProvider } from '@logichat/angular/services'
import { PaymentConfig } from '../models'
import { PaymentService } from '../services'

@NgModule({
  imports: [CommonModule],
})
export class PaymentModule {
  static forRoot(config: PaymentConfig): ModuleWithProviders<PaymentModule> {
    return {
      ngModule: PaymentModule,
      providers: [WindowService, windowProvider, PaymentService, { provide: PaymentConfig, useValue: config }],
    }
  }
}
